import { styled } from "@mui/material/styles";
import BadgeUnstyled from "@mui/base/BadgeUnstyled";

export const shapeStyles = {
  bgcolor: "#F3F6FF",
  width: "5.6rem",
  height: "5.6rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: " all ease-in-out .4s",
  borderRadius: "2rem",
  "&:hover .bell": {
    color: "#fff !important",
  },
  "&:hover": {
    bgcolor: "#643fdb",
  },
};
export const BellStyle = {
  transition: " all ease-in-out .4s",
  color: "#1C1243",
  zIndex: "2",
  width: "1.8rem",
  height: "1.8rem",
};

export const StyledBadge = styled(BadgeUnstyled)`
  box-sizing: border-box;
  margin: 0 2.4rem;
  cursor: pointer;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1.4rem;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  position: relative;
  display: inline-block;
  line-height: 1;
  transition: all ease-in-out 0.4s;

  & .MuiBadge-badge {
    z-index: auto;
    min-width: 2rem;
    height: 2rem;
    padding: 0 0.6rem;
    color: #fff;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2rem;
    white-space: nowrap;
    text-align: center;
    background: #ff4d4f;
    border-radius: 1rem;
  }

  & .MuiBadge-dot {
    padding: 0;
    z-index: auto;
    min-width: .6rem;
    width: 1.4rem;
    height: 1.4rem;
    background: #643fdb;
    border-radius: 100%;
    border: 2px solid #fff;
    box-shadow: 0 0 0 1px #fff;
  }

  & .MuiBadge-anchorOriginTopRightCircular {
    position: absolute;
    top: 6px;
    border: 2px solid #fff;
    right: 6px;
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
  }
`;
