export const Container = {
  width: "100%",
  padding: "2.4rem",
  bgColor: "default.main",
  boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
  borderRadius: "2rem",
};

export const Title = {
  fontSize: "2.4rem",
  fontFamily: "NetflixBold",
};

export const Time = {
  fontSize: "1.2rem",
  fontFamily: "NetflixMedium",
  color: "text.details",
};
