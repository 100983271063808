import React from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import InputHandler from "./inputHandler";
import InputAdornment from "@mui/material/InputAdornment";
import { Box } from "@mui/material";
import EmailIcon from "../../../../assets/icons/email-icon.png";
import UserIcon from "../../../../assets/icons/user-icon-violet.png";
import BrandIcon from "../../../../assets/icons/brand-icon.png";
import TaxIcon from "../../../../assets/icons/tax-icon.png";
import AddressIcon from "../../../../assets/icons/address-icon.png";
import PhoneIcon from "../../../../assets/icons/phone-icon.png";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { FieldIcon, SubmitBtn } from "./style";
import { deleteCompany } from "../../../../store/actions";

const GeneralInfo = ({ UnverifiedFacilities, deleteCompany }) => {
  const { id } = useParams();
  const router = useNavigate();
  const CurrentFacility = UnverifiedFacilities?.filter(
    (facility) => facility.id === id
  );
  console.log(CurrentFacility);
  return (
    <Box sx={{ flexGrow: 1, mt: 4 }}>
      {/*  FORMATTED AS REDUX */}
      <Formik
        initialValues={{
          username: CurrentFacility[0]["user_info"].username,
          email: CurrentFacility[0]["user_info"].email,
          company_name: CurrentFacility[0]["user_info"].company_name,
          company_tax_number:
            CurrentFacility[0]["user_info"].company_tax_number,
          address: CurrentFacility[0]["user_info"].address,
          phone_number: CurrentFacility[0]["user_info"].phone_number,
        }}
        enableReinitialize={true}
        onSubmit={() => {
          const company = CurrentFacility[0];
          deleteCompany(company);
          router("/companies");
        }}
      >
        {(formikProps) => (
          <Form>
            {/* ======== Fullname */}
            <Box sx={{ maxWidth: "80rem" }}>
              <InputHandler
                placeholder={"Full Name"}
                name={"username"}
                type={"text"}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <FieldIcon src={UserIcon} />
                    </InputAdornment>
                  ),
                }}
              />
              {/* ======== EMAIL */}
              <InputHandler
                placeholder={"Email"}
                name={"email"}
                type={"email"}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <FieldIcon src={EmailIcon} />
                    </InputAdornment>
                  ),
                }}
              />
              {/* ======== BRANDNAME */}
              <InputHandler
                placeholder={"Brand Name"}
                name={"company_name"}
                type={"text"}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <FieldIcon src={BrandIcon} />
                    </InputAdornment>
                  ),
                }}
              />
              {/* ======== TAXNO */}
              <InputHandler
                placeholder={"Company Tax Number"}
                name={"company_tax_number"}
                type={"number"}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <FieldIcon src={TaxIcon} />
                    </InputAdornment>
                  ),
                }}
              />
              {/* ======== Address */}
              <InputHandler
                placeholder={"Address"}
                name={"address"}
                type={"text"}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <FieldIcon src={AddressIcon} />
                    </InputAdornment>
                  ),
                }}
              />
              {/* ======== PhoneNumber */}
              <InputHandler
                placeholder={"Phone Number"}
                name={"phone_number"}
                type={"text"}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <FieldIcon src={PhoneIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <SubmitBtn type={"submit"}>Delete Company</SubmitBtn>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

const mapStateTopProps = (state) => {
  return {
    UnverifiedFacilities: state.admin.UnverifiedFacilities,
  };
};

export default connect(mapStateTopProps, { deleteCompany })(GeneralInfo);
