import { styled, TextField } from "@mui/material";

export const DateInput = styled(TextField)({
  "& label": {
    display: "none",
  },
  "& .MuiOutlinedInput-root": {
    fontFamily: "NetflixMedium !important",
    background: "#F3F6FF",
    width: "100%",
    borderRadius: "2rem",
    color: "#A29EB6",
    "& fieldset": {
      border: "none",
      fontFamily: "NetflixMedium !important",
      color: "#A29EB6",
    },
    "&.Mui-focused fieldset": {
      border: "2px solid",
      borderColor: "#643fdb",
    },
  },
});
