import React, { useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import {
  FieldIcon,
  SideImage,
  SubmitBtn,
  TitleStyle,
  nImageContainer,
  form,
  formContainer,
} from "./style";
import InputHandler from "./inputHandler";
import Background from "../../assets/images/login-screen.png";
import EmailIcon from "../../assets/icons/email-icon.png";
import PasswordIcon from "../../assets/icons/password-icon.png";
import EyeIcon from "../../assets/icons/eye-icon.png";
import { connect } from "react-redux";
import { login, GetUnverifiedFacilities } from "../../store/actions";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
// validation schema
const validate = Yup.object({
  email: Yup.string().required("required").email("Email is invalid"),
  password: Yup.string().required("required"),
});
const Login = ({ login, errors }) => {
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const router = useNavigate();
  return (
    <Box sx={{ flexGrow: 1, width: "100%", height: "98vh" }}>
      <Grid
        container
        width={"100%"}
        height={"100%"}
        sx={{ bgcolor: "#fff", borderRadius: "20px" }}
      >
        <Grid item lg={6} xs={12} sx={formContainer}>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={validate}
            onSubmit={async (values) => {
              login(values);
              setLoading(true);
              router("/dashboard");
            }}
          >
            {(formikProps) => (
              <Form style={form}>
                <Typography
                  variant="h2"
                  component="div"
                  sx={TitleStyle}
                  gutterBottom
                >
                  Sign in
                </Typography>
                <Typography
                  variant={"span"}
                  component={"div"}
                  sx={{
                    fontSize: 16,
                    color: "#fc5a5a",
                    fontFamily: "NetflixMedium",
                  }}
                >
                  {errors && errors.message}
                </Typography>
                <InputHandler
                  placeholder={"Write your email"}
                  name={"email"}
                  type={"email"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FieldIcon src={EmailIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <InputHandler
                  placeholder={"Write your password"}
                  name={"password"}
                  type={showPass ? "text" : "password"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FieldIcon src={PasswordIcon} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => {
                          setShowPass(!showPass);
                        }}
                      >
                        <FieldIcon src={EyeIcon} />
                      </InputAdornment>
                    ),
                  }}
                />

                <SubmitBtn type={"submit"}>
                  {(loading && !errors && (
                    <CircularProgress sx={{ color: "#fff" }} />
                  )) ||
                    "Sign in"}
                </SubmitBtn>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item lg={6} xs={12}>
          <div sx={nImageContainer}>
            <SideImage src={Background} />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

const MapStateToProps = (state) => {
  return {
    errors: state.admin.LoginErrors,
  };
};
export default connect(MapStateToProps, { login, GetUnverifiedFacilities })(
  Login
);
