import React from "react";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Login from "./components/Login/Login";
import Navigation from "./components/Navigation/Navigation";
import { getAllSportTypes } from "./store/actions";



const App = ({ isLogin,getAllSportTypes }) => {
  getAllSportTypes();
  return <BrowserRouter>{isLogin ? <Navigation /> : <Login />}</BrowserRouter>;
};
const mapStateToProps = (state) => {
  return {
    isLogin: state.auth,
  };
};
export default connect(mapStateToProps, { getAllSportTypes })(App);
