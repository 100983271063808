import {Box, Container} from "@mui/material";
import React from "react";
import {connect} from "react-redux";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import {Tab, TabPanel, TabsList} from "./style";
import ProfileInfo from "./ProfileInfo/ProfileInfo";

const Setting = ({}) => {
    return <Box sx={{flexGrow: 1}}>
        <Container xl={"lg"}>
            <TabsUnstyled defaultValue={0} sx={{width: "80%"}}>
                <TabsList>
                    <Tab>Profile Info</Tab>
                    <Tab>Notification</Tab>
                </TabsList>
                <TabPanel value={0}>
                    <ProfileInfo/>
                </TabPanel>
                <TabPanel value={1}>
                    mohamed
                </TabPanel>
            </TabsUnstyled>
        </Container>
    </Box>;
};
const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Setting);
