import TextField from "@mui/material/TextField";
import { styled, Button } from "@mui/material";

export const InputField = styled(TextField)({
  "& label": {
    display: "none",
  },
  "& .MuiOutlinedInput-root": {
    marginBottom: "2.4rem",
    background: "#fff",
    height: "7rem",
    fontSize: "1.6rem",
    fontFamily: "NetflixMedium",
    color: "#1c1243",
    "& fieldset": {
      border: "none",
      boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.06)",
      borderRadius: "1.6rem",
      padding: "0 3.6rem",
    },
    "&.Mui-focused fieldset": {
      border: "2px solid",
      borderColor: "#643fdb",
    },
    "&.Mui-disabled .MuiOutlinedInput-input value": {
      color: "#1c1243",
    },
  },
});
export const FieldIcon = styled("img")(({ theme }) => ({
  width: "2.7rem",
  cursor: "pointer",
  marginRight: "1.2rem",
}));
export const SubmitBtn = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#643fdb",
  height: "5.8rem",
  borderRadius: "1rem",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.14)",
  marginTop: "3.2rem",
  width: "100%",
  "&:hover": {
    backgroundColor: "#643fdb",
    color: "#fff",
  },
}));
