import { styled, InputBase } from "@mui/material";

export const InputSelect = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 20,
    fontFamily: "NetflixBold",
    background: "#F3F6FF",
    padding: "0",
    color: "#A29EB6",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1.6rem 2.6rem 1.6rem 1.2rem",
    "&:focus": {
      borderRadius: 20,
      color: "#A29EB6",
    },
  },
}));
