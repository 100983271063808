export const IconContainer = {
  width: "48px",
  height: "48px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
export const Value = {
  fontSize: "1.8rem",
  fontFamily: "NetflixBold",
  mb: 1,
};
export const Name = {
  fontSize: "1.4rem",
  fontFamily: "NetflixMedium",
  color: "text.details",
};
