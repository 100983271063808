import React from "react";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
const Title = ({ username }) => {
  const location = useLocation().pathname;

  const renderTitle = () => {
    if (location.includes("dashboard") || location === "/") {
      return (
        <Typography
          variant={"h1"}
          component={"div"}
          fontFamily={"NetflixMedium"}
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "baseline",
            fontSize: "2.4rem",
          }}
        >
          Welcome,
          <Typography
            variant={"h1"}
            component={"div"}
            color={"#643fdb"}
            fontFamily={"NetflixBold"}
            sx={{ fontSize: "2.4rem", textTransform: "capitalize" }}
          >
            {username}
          </Typography>
        </Typography>
      );
    } else if (location.includes("reservation")) {
      return (
        <Typography
          variant={"h4"}
          component={"div"}
          fontFamily={"NetflixMedium"}
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "baseline",
            fontSize: "2.4rem",
          }}
        >
          Reservation
        </Typography>
      );
    } else if (location.includes("users")) {
      return (
        <Typography
          variant={"h4"}
          component={"div"}
          fontFamily={"NetflixMedium"}
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "baseline",
            fontSize: "2.4rem",
          }}
        >
          Users
        </Typography>
      );
    } else if (location.includes("companies")) {
      return (
        <Typography
          variant={"h4"}
          component={"div"}
          fontFamily={"NetflixMedium"}
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "baseline",
            fontSize: "2.4rem",
          }}
        >
          Companies
        </Typography>
      );
    } else if (location.includes("setting")) {
      return (
        <Typography
          variant={"h4"}
          component={"div"}
          fontFamily={"NetflixMedium"}
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "baseline",
            fontSize: "2.4rem",
          }}
        >
          Setting
        </Typography>
      );
    }
  };

  return <>{renderTitle()}</>;
};
const mapStateToProps = (state) => ({
  username: state.admin.LoginResponse?.data?.my_info?.username,
});
export default connect(mapStateToProps, {})(Title);
