import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const ReservationModal = ({ handleClose, open, data }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={style}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box onClick={handleClose} sx={{bgcolor:"#fff"}}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {data.reserver_name}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
        </Typography>
      </Box>
    </Modal>
  );
};

export default ReservationModal;
