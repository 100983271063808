import * as React from "react";
import {
  Box,
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Button,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Logo from "./Logo/Logo";
import Header from "./Header/Header";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import HomeIcon from "@mui/icons-material/Home";
import DateRangeIcon from "@mui/icons-material/DateRange";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ChairIcon from "@mui/icons-material/Chair";
import SettingsIcon from "@mui/icons-material/Settings";
import { logOutButton, NavButton } from "./style";
import Reservations from "../Reservation/Reservations";

import Users from "../Users/Users";
import Companies from "../Companies/Companies";
import Setting from "../Settings/Setting";
import { connect } from "react-redux";
import { signOut } from "../../store/actions";
import CompanyPage from "../Companies/SingleCompany/CompanyPage";

const drawerWidth = 240;

const Navigation = ({ signOut }) => {
  const router = useNavigate();
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          boxShadow: "none",
          mt: "1.2rem",
        }}
      >
        <Header />
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            bgcolor: "background.drawer",
            overflow: "hidden",
            borderRight: "0",
            borderRadius: "0 3.6rem 3.6rem 0",
            px: 2,
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Logo />
        <Toolbar />
        <List>
          {[
            { text: "Dashboard", icon: <HomeIcon /> },
            { text: "Reservation", icon: <DateRangeIcon /> },
            { text: "Users", icon: <PersonOutlineIcon /> },
            { text: "Companies", icon: <ChairIcon /> },
            //   { text: "Setting", icon: <SettingsIcon /> },
          ].map((item, index) => (
            <NavButton
              size="large"
              key={index}
              startIcon={item.icon}
              onClick={() => {
                router(`/${item.text.toLowerCase()}`);
              }}
            >
              {item.text}
            </NavButton>
          ))}
        </List>
        <Button
          sx={logOutButton}
          startIcon={<ExitToAppIcon />}
          size="large"
          onClick={() => {
            signOut();
          }}
        >
          <b>Logout</b>
        </Button>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 3,
          mt: "9rem",
        }}
      >
        <Routes>
          <Route path="/dashboard" exact element={<Dashboard />} />
          <Route path="/" exact element={<Dashboard />} />
          <Route path="/reservation" exact element={<Reservations />} />
          <Route path="/users" exact element={<Users />} />
          <Route path="/companies" exact element={<Companies />} />
          <Route path="/companies/:id" exact element={<CompanyPage />} />
          <Route path="/setting" exact element={<Setting />} />
        </Routes>
      </Box>
    </Box>
  );
};
export default connect(null, { signOut })(Navigation);
