import { Box, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import StatisticsBar from "./StatisticsBar/StatisticsBar";
import UsersBarChart from "./usersBarChart/UsersBarChart";
import { GetUnverifiedFacilities } from "../../store/actions";

const Dashboard = () => {


  return (
    <div>
      <StatisticsBar />
      <UsersBarChart />
    </div>
  );
};
export default Dashboard;
