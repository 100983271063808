import React from "react";
import { Grid } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import MoreIcon from "./MoreIcon";
import { deleteCompany } from "../../store/actions";

const EditAndDeleteButtons = ({ company, loader, isApproved }) => {
  // console.log(company);
  const router = useNavigate();
  const [secondOpen, setSecondOpen] = React.useState(false);

  return (
    <Grid container alignItems="center">
      <Grid item xs={6}>
        <MoreIcon
          element={company}
          action={deleteCompany}
          loader={loader}
          isApproved={isApproved}
        />
      </Grid>
    </Grid>
  );
};
export default EditAndDeleteButtons;
