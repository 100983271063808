
  
  export const title = {
    marginBottom: "20px",
    color:"#F5365C",
    fontSize:'1.6rem',
  }

  export const modal= {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
  export const modalBody={
   fontSize:'1.6rem',
    color:"#4a3b85",
   
  }
  
  export const buttonNo={
    marginTop: "40px",
    marginBottom: "60px",
    height: "50px",
    textTransform: "capitalize",
    fontWeight: "bolder",
    borderRadius: "10px",
    fontSize: "14px",
    backgroundColor: "#F5365C",
    color: "white",
    transition: "all .5s ease-in-out",
    "&:hover": {
        backgroundColor: "#f45f7a",
      color: "white",
    },
  }
  export const buttonYes= {
    marginTop: "40px",
    marginBottom: "60px",
    height: "50px",
    textTransform: "capitalize",
    fontWeight: "bolder",
    borderRadius: "10px",
    fontSize: "14px",
    backgroundColor: "#2DCE89",
    color: "white",
    transition: "all .5s ease-in-out",
    "&:hover": {
        backgroundColor: "#82ccac",
      color: "white",
    },
  }
  export const modalPaper= {
    position: "relative",
   // backgroundColor: theme.palette.background.paper,
    padding: "20px 60px",
    borderRadius: "30px",
   // boxShadow: theme.shadows[5],
    maxWidth: "900px",
  }
  export const closeButton={
    position: "asolute",
    left: "60px",
    bottom: "10px",
    fontSize:'1.6rem',
  }
  
