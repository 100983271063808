import React from "react";
import { ModalStyle, ModalTopBar, ModalTitle } from "./style";
import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ChangePhone = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle}>
        <Box sx={ModalTopBar}>
          <ModalTitle variant="h1" component="div">
            Change Phone
          </ModalTitle>
          <CloseIcon
            sx={{ fontSize: "32px" }}
            onClick={() => {
              handleClose();
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};
export default ChangePhone;
