import React from "react";
import {ErrorMessage, useField} from "formik";
import {InputField} from './style'

const InputHandler = (props) => {
    const [field, meta] = useField(props);
    return (
        <div>
            <InputField
                error={meta.touched && meta.error ? true : false}
                fullWidth={true}
                autoComplete="off"
                helperText={<ErrorMessage name={field.name}  />}
                {...field}
                {...props}
            />
        </div>
    )
}
export default InputHandler
