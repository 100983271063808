import React from "react";
import Paper from "@mui/material/Paper";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Typography } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  borderRadius: 10,
  barThickness: 10,
  borderSkipped: false,
  borderColor: ["#EF5DA8"],
  backgroundColor: ["#EF5DA8"],
  scales: {
    yAxis: {
      display: false,
      min: 0,
      max: 100,
      stepSize: 10,
    },
    xAxis: {
      display: false,
      min: 0,
      max: 100,
      stepSize: 10,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: false,
      text: "2.7k",
    },
  },
};

const labels = ["", "", "", "", ""];

export const data = {
  labels,
  datasets: [
    {
      data: [50, 50, 70, 90, 10],
    },
  ],
};

const UsersBarChart = () => {
  return (
    <Paper sx={PaperStyle}>
      <Typography
        variant="h1"
        component="div"
        sx={{ fontSize: "1.6rem", fontFamily: "NetflixBold", color: "#1C1243" }}
      >
        Users
      </Typography>
      <Typography
        variant="h1"
        component="div"
        sx={{
          fontSize: "2.4rem",
          fontFamily: "NetflixBold",
          color: "#5E5873",
          mt: 3,
        }}
      >
        24.5K
      </Typography>
      <Bar height={300} options={options} data={data} />
    </Paper>
  );
};

export default UsersBarChart;

const PaperStyle = {
  boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
  mt: 2,
  padding: "2rem 1rem 1rem 2rem",
  borderRadius: ".6rem",
  maxWidth: "20rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
};
