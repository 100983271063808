import React from "react";
import Grid from "@mui/material/Grid";
import LogoImage from "../../../assets/images/Logo.png";

const Logo = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ mt: "3.8rem" }}
    >
      <Grid item xs={11} justifyContent="center" alignItems="center">
        <img
          src={LogoImage}
          alt="logo"
          width={"100%"}
          style={{ objectFit: "cover" }}
        />
      </Grid>
    </Grid>
  );
};

export default Logo;
