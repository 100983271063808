import React from "react";
import {
  IconButton,
  Modal,
  Grid,
  Typography,
  Backdrop,
  Button,
  Divider,
  Paper,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import MoreIcon from "./MoreIcon";
import {
  buttonIconEdit,
  buttonIconDelete,
  modal,
  modalPaper,
  title,
  closeButton,
  buttonDelete,
  span,
  buttonAdd,
} from "./style";

const EditAndDeleteButtons = ({ reservation }) => {
  const router = useNavigate();
  const [secondOpen, setSecondOpen] = React.useState(false);

  const handleSecondOpen = () => {
    setSecondOpen(true);
  };
  const handleSecondClose = () => {
    setSecondOpen(false);
  };
  const handleEdit = async () => {
    if (reservation) {
      router.push(`/games/edit/${reservation.id}`);
    }
  };
  return (
    <React.Fragment>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          {/* <Button sx={buttonIconEdit}  color="primary" onClick={handleEdit}>
            Cancel
          </Button> */}
        </Grid>
        <Grid item xs={6}>
          <MoreIcon />
        </Grid>
      </Grid>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        sx={modal}
        open={secondOpen}
        onClose={handleSecondClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper sx={modalPaper} elevation={0}>
          <Grid container item justifyContent="space-between">
            <Grid item xs={11}>
              <Typography sx={title} variant="h6" color="secondary">
                <b>Delete Game</b>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                sx={closeButton}
                edge="end"
                color="inherit"
                onClick={handleSecondClose}
                aria-label="close"
              >
                <CloseIcon color="disabled" />
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
          <Grid style={{ marginTop: "40px" }} container item>
            <Grid container justifyContent="center" item xs={12} spacing={3}>
              <Typography variant="body1" color="secondary" gutterBottom={true}>
                <b>Are You Sure You Want To Delete This Game?!</b>
              </Typography>
              <Grid item xs={6}>
                <Button
                  sx={buttonDelete}
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    console.log("Delete");
                  }}
                >
                  <span sx={span}>Delete</span>
                </Button>
              </Grid>
              <Grid style={{ color: "red" }} item xs={6}>
                <Button
                  sx={buttonAdd}
                  variant="outlined"
                  fullWidth
                  onClick={() => handleSecondClose()}
                >
                  <span sx={span}>Cancel</span>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </React.Fragment>
  );
};
export default connect(null, {})(EditAndDeleteButtons);
