import React from "react";
import Title from "./Title/Title";
import Searchbar from "./Search/Searchbar";
import { Box, Toolbar } from "@mui/material";
import { NavContainer } from "./style";
import Notifications from "./Notifications/Notifications";
import Profile from "./Profile/Profile";

const Header = () => {
  return (
    <Box sx={{ flexGrow: 1, m: 0 }}>
      <NavContainer>
        <Toolbar>
          <Title />
          <Searchbar />
          <Notifications />
          <Profile />
        </Toolbar>
      </NavContainer>
    </Box>
  );
};
export default Header;
