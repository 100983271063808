import { styled, Typography } from "@mui/material";

export const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    maxWidth: "760px",
    maxHeight: "460px",
    width: "100%",
    height: "100%",
    background: "#fff",
    borderRadius: "20px",
    padding: "12px 32px",
  };
  
  export const ModalTopBar = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    py: 2,
    borderBottom: "1px solid #F1F1F5",
    flexGrow: 1,
  };
  export const ModalTitle = styled(Typography)({
    fontSize: "18px",
    fontFamily: "NetflixMedium",
  });