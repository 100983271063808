import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#643FDB",
    },
    secondary: {
      main: "#51bf5c",
    },
    default: {
      main: "#ffffff",
    },
    error: {
      main: "#F12B2C",
    },
    background: {
      drawer: "#643FDB",
    },
    text: {
      primary: "#1C1243",
      secondary: "#643FDB",
      default: "#FFFFFF",
      details: "#6E6B7B",
      tableHead:"#696974"
    },
  },
});

export default theme;
