import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";

import { styled, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";

export const TabsList = styled(TabsListUnstyled)`
  min-width: 32rem;
  background-color: #eff1f3;
  margin-bottom: 1.6rem;
  display: flex;
  padding: 0 0.8rem;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;
export const Tab = styled(TabUnstyled)`
  cursor: pointer;
  font-family: NetflixMedium;
  color: #808191;
  font-size: 1.6rem;
  border-radius: 1.2rem;
  width: 100%;
  padding: 1.4rem 0;
  margin: 0.5rem 0.6rem 0.5rem 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #643fdb;
    font-family: NetflixBold;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    background-color: #fff;
    color: #643fdb;
    outline: none;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #fff;
    color: #643fdb;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
export const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
`;

export const InputField = styled(TextField)({
  width: "100%",
  "& label": {
    display: "none",
  },
  "& .MuiOutlinedInput-root": {
    marginBottom: "2.4rem",
    background: "#fff",
    height: "7rem",
    fontSize: "1.6rem",
    fontFamily: "NetflixMedium",
    color: "#1c1243",
    width: "100%",
    "& fieldset": {
      border: "none",
      boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.06)",
      borderRadius: "1.6rem",
      padding: "0 3.6rem",
    },
    "&.Mui-focused fieldset": {
      border: "2px solid",
      borderColor: "#643fdb",
    },
    "&.Mui-disabled .MuiOutlinedInput-input value": {
      color: "#1c1243",
    },
  },
});

export const ChangeButton = styled(Typography)({
  fontSize: "14px",
  fontFamily: "NetflixMedium",
  color: "#643fdb",
  cursor: "pointer",
  padding:"0 12px"
});
