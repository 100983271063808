import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { IconContainer, Value, Name } from "./style";
export default function Card({ data }) {
  return (
    <Grid container item lg={3} md={4} sm={6} xs={12} alignItems={"center"}>
      <Grid item container spacing={3} alignItems="center">
        <Grid item>
          <Box sx={{ ...IconContainer, backgroundColor: data.color }}>
            <img src={data.icon} alt="icon" width={"24px"} height={"24px"} />
          </Box>
        </Grid>
        <Grid item>
          <Typography variant="h1" component="div" sx={Value}>
            {data.value}
          </Typography>
          <Typography variant="h1" component="div" sx={Name}>
            {data.name}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
