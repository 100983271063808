import React from "react";
import { Grid, Button } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import MoreIcon from "./MoreIcon";
import { buttonIconEdit } from "./style";
import { cancelReservation } from "../../store/actions";
const EditAndDeleteButtons = ({ reservation }) => {
  const handleStatus = () => {
    if (
      reservation.status === "ACTIVE_RESERVATION" &&
      parseInt(reservation.time) > Date.now() / 1000
    ) {
      return (
        <Button sx={{ ...buttonIconEdit, backgroundColor: "primary.main" }}>
          UPCOMING
        </Button>
      );
    } else if (reservation.status === "CANCELED_RESERVATION") {
      return (
        <Button sx={{ ...buttonIconEdit, backgroundColor: "error.main" }}>
          CANCEL
        </Button>
      );
    } else if (
      reservation.status === "ACTIVE_RESERVATION" &&
      parseInt(reservation.time) < Date.now() / 1000
    ) {
      return (
        <Button sx={{ ...buttonIconEdit, backgroundColor: "secondary.main" }}>
          DONE
        </Button>
      );
    }
    // switch (reservation.status) {
    //   case "ACTIVE_RESERVATION":
    //     return (
    //       <Button sx={{ ...buttonIconEdit, backgroundColor: "primary.main" }}>
    //         UPCOMING
    //       </Button>
    //     );
    //   case "CANCELED_RESERVATION":
    //     return (
    //       <Button sx={{ ...buttonIconEdit, backgroundColor: "error.main" }}>
    //         CANCEL
    //       </Button>
    //     );
    //   case parseInt(reservation.time) > Date.now() / 1000:
    //     return (
    //       <Button sx={{ ...buttonIconEdit, backgroundColor: "primary.main" }}>
    //         DONE
    //       </Button>
    //     );
    //     break;

    //   default:
    //     break;
    // }
  };
  return (
    <React.Fragment>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          {handleStatus()}
        </Grid>
        <Grid item xs={6}>
          {/* <MoreIcon element={reservation} action={cancelReservation} /> */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default connect(null, {})(EditAndDeleteButtons);
