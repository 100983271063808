import { Grid, Typography } from "@mui/material";
import React from "react";
import Select from "./Selector/Select";
import MenuItem from "@mui/material/MenuItem";

const Filterbar = ({ handleFilter, filterValue, Sports, Time }) => {
  return (
    <Grid container spacing={4} alignItems="center">
      <Grid item>
        <Select
          label="Show"
          data={Time}
          handleChange={handleFilter}
          value={filterValue}
        />
      </Grid>
      <Grid item>
        <Select
          label="Sport"
          data={Sports}
          handleChange={handleFilter}
          value={filterValue}
        />
      </Grid>
    </Grid>
  );
};

export default Filterbar;
