import React, { useState } from "react";
import { Box, Grid, Modal } from "@mui/material";
import InputHandler from "./inputHandler";
import { Form, Formik } from "formik";
import InputAdornment from "@mui/material/InputAdornment";
import { FieldIcon } from "../../Companies/SingleCompany/GeneralInfo/style";
import UserIcon from "../../../assets/icons/user-icon-violet.png";
import passwordIcon from "../../../assets/icons/password-icon.png";
import phoneIcon from "../../../assets/icons/phone-icon.png";
import EmailIcon from "../../../assets/icons/email-icon.png";
import ProfilePicture from "./ProfilePicture/ProfilePicture";
import { connect } from "react-redux";
import { ChangeButton } from "../style";
import ChangePassword from "./ChangePassword/ChangePassword";
import ChangePhone from "./ChangePhone/ChangePhone";

function ProfileInfo({ profilePic }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openPhone, setOpenPhone] = useState(false);
  const handleOpenPhone = () => setOpenPhone(true);
  const handleClosePhone = () => setOpenPhone(false);
  return (
    <Box sx={{ flexGrow: 1, mt: 4 }}>
      <Grid container spacing={2}>
        <Grid item container lg={6} xs={6}>
          <Formik
            style={{ width: "100%" }}
            initialValues={{
              fullname: "saif",
              email: "horeyatm@gmail.com",
              phone_number: "01206944093",
              password: "121212",
            }}
            enableReinitialize={true}
          >
            {(formikProps) => (
              <Form style={{ width: "100%" }}>
                <InputHandler
                  placeholder={"Full Name"}
                  name={"fullname"}
                  type={"text"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FieldIcon src={UserIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <InputHandler
                  placeholder={"Email"}
                  name={"email"}
                  type={"email"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FieldIcon src={EmailIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <InputHandler
                  placeholder={"Password"}
                  name={"password"}
                  type={"password"}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <FieldIcon src={passwordIcon} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <ChangeButton
                          variant="h1"
                          component="div"
                          onClick={() => {
                            handleOpen();
                          }}
                        >
                          change
                        </ChangeButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ChangePassword open={open} handleClose={handleClose} />
                <InputHandler
                  placeholder={"Phone Number"}
                  name={"phone_number"}
                  type={"text"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FieldIcon src={phoneIcon} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <ChangeButton
                          variant="h1"
                          component="div"
                          onClick={() => {
                            handleOpenPhone();
                          }}
                        >
                          change
                        </ChangeButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ChangePhone open={openPhone} handleClose={handleClosePhone} />
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item container lg={6} xs={12}>
          <ProfilePicture profilePic={profilePic} />
        </Grid>
      </Grid>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  profilePic: state.admin.LoginResponse?.data?.my_info?.profile_pic,
});
export default connect(mapStateToProps, {})(ProfileInfo);
