import {Button, styled} from "@mui/material";

export const ImageStyle = {
    minWidth: "280px",
    minHeight: "280px",
    height: "100%",
    width: "100%",
    objectFit: "cover",
    borderRadius: "20px"
}

export const UploadButton = styled(Button)({
    color: "#643fdb",
    backgroundColor: "#fff",
    padding:"12px 24px",
    borderRadius: "1rem",
    border:"1px solid #EBE6FD",
    fontSize:"1.4rem",
    fontFamily:"NetflixMedium",
    width: "100%",
    "&:hover": {
        backgroundColor: "#643fdb",
        color: "#fff",
    },
})

export const RemoveButton = styled(Button)({
    color: "#EB4C4C",
    backgroundColor: "transparent",
    borderRadius: "1rem",
    fontSize:"1.4rem",
    fontFamily:"NetflixMedium",
    width: "100%",
    "&:hover": {
        color: "rgba(235, 76, 76, .5)",
        backgroundColor: "transparent",
    },
})