import { Paper, styled, Typography } from "@mui/material";
import FootballPitch from "../../../../../assets/images/Football-pitch.png";

export const Pitch = styled(Paper)({
  minHeight: "19rem",
  minWidth: "41rem",
  maxWidth: "45rem",
  padding: "1.8rem",
  borderRadius: "2.4rem",
  background: `url(${FootballPitch}) no-repeat center center`,
  backgroundSize: "cover",
});
export const PitchName = styled(Typography)({
  fontSize: "2.2rem",
  fontFamily: "NetflixMedium",
  color: "#fff",
});
