import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import adminReducer from "./adminReducer";
import AuthReducer, { TokenReducer } from "./AuthReducer";
import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";

export const rootReducer = combineReducers({
  admin: adminReducer,
  auth: AuthReducer,
  token: TokenReducer,
});

const configStorage = {
  key: "root",
  storage,
  whitelist: ["auth", "token"],
  // stateReconciler: hardSet,
};
export default persistReducer(configStorage, rootReducer);
