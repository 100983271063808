import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import DatePicker from "./DatePicker/DatePicker";
import Select from "./Selector/Select";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

function valuetext(value) {
  return `${value}°C`;
}

function SliderComponent({ price, handlePriceChange, setPrice }) {
  const [timeoutID, setTimeoutID] = useState();

  const handleChange = (event, newValue) => {
    setPrice(newValue);

    clearTimeout(timeoutID);

    setTimeoutID(
      setTimeout(() => {
        handlePriceChange();
      }, 2000)
    );
  };
  return (
    <Box sx={{ width: 150 }}>
      <Typography
        id="input-slider"
        gutterBottom
        sx={{ fontFamily: "NetflixBold", color: "#A29EB6" }}
      >
        Price
      </Typography>
      <Slider
        label="price"
        getAriaLabel={() => "Price Range"}
        value={price}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
      />
    </Box>
  );
}
const Filterbar = ({ price, handlePriceChange, setPrice }) => {
  const Sports = ["Football", "Karting"];
  const Time = ["AM", "PM"];

  return (
    <Grid container spacing={4} alignItems="center">
      <Grid item>
        <DatePicker />
      </Grid>
      <Grid item>
        <Select label="Sport" data={Sports} />
      </Grid>
      <Grid item>
        <Select label="Time" data={Time} />
      </Grid>
      <Grid item>
        <SliderComponent
          price={price}
          handlePriceChange={handlePriceChange}
          setPrice={setPrice}
        />
      </Grid>
    </Grid>
  );
};

export default Filterbar;
