import React from "react";
import { SearchBar } from "./style";
import InputAdornment from "@mui/material/InputAdornment";
import SearchOffOutlined from "@mui/icons-material/Search";
import { useDispatch } from "react-redux";
import { companySearch } from "../../../../store/actions";
const Searchbar = () => {
  let id = "";
  const dispatcher = useDispatch();
  return (
    <SearchBar
      placeholder={"Search..."}
      name={"search"}
      size={"normal"}
      onKeyPress={(e) => {
        id = e.target.value;
        if (e.key === "Enter" && e.target.value) {
          dispatcher(companySearch(e.target.value));
        }
      }}
      onKeyUp={(e) => {
        id = e.target.value;
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchOffOutlined
              sx={{
                color: "#1C1243",
                cursor: "pointer",
                fontSize: "24px",
                transition: "all ease-in-out .3s",
                "&:hover": {
                  color: "#643fdb",
                  transform: "scale(1.5)",
                },
              }}
              onClick={() => {
                dispatcher(companySearch(id));
              }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Searchbar;
