import React from "react";
import {
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  IconButton,
} from "@mui/material";
import { buttonIconMore, moreDropDown, rootMoreIcon, menuItem } from "./style";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { connect } from "react-redux";
import AlertModal from "../Global/AlertModal/AlertModal";
import { deleteCompany, approveCompany } from "../../store/actions";
const MoreIcon = ({ element, action, loader, isApproved }) => {
  const [open, setOpen] = React.useState(false);
  const [openModalApprove, setOpenModalApprove] = React.useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleCloseModal = () => {
    setOpenModalApprove(false);
    setOpenModalDelete(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const handleApproveCompany = () => {
    setOpenModalApprove(true);
  };
  const handleDeleteCompany = () => {
    setOpenModalDelete(true);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={rootMoreIcon}>
      <div>
        <IconButton
          sx={buttonIconMore}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <MoreVertIcon />
        </IconButton>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper sx={moreDropDown}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {!isApproved && (
                      <MenuItem sx={menuItem} onClick={handleApproveCompany}>
                        Approve Company
                      </MenuItem>
                    )}
                    <MenuItem sx={menuItem} onClick={handleDeleteCompany}>
                      Delete Company
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <AlertModal
          open={openModalApprove}
          handleClose={handleCloseModal}
          action={approveCompany}
          element={element}
          type="Approve"
          loader={loader}
        />
        <AlertModal
          open={openModalDelete}
          handleClose={handleCloseModal}
          element={element}
          action={deleteCompany}
          type="Delete"
        />
      </div>
    </div>
  );
};
export default connect(null, {})(MoreIcon);
