import React from 'react';
import {Box, Grid} from "@mui/material";
import ImageSettings from '../../../../assets/images/settings-demo.png'
import {ImageStyle, RemoveButton, UploadButton} from "./style";

function ProfilePicture({profilePic}) {
    return (
        <Box sx={{flexGrow: 1}}>
            <Grid container direction={"column"} spacing={2} alignItems={"center"}>
                <Grid item xs={12}>
                    <img src={profilePic} style={ImageStyle} alt=""/>
                </Grid>
                <Grid item xs={12}>
                    <UploadButton>Upload new Picture</UploadButton>
                </Grid>
                <Grid item xs={12}>
                    <RemoveButton>Remove picture</RemoveButton>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ProfilePicture;