import React, { useEffect } from "react";
import {
  Alert,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { connect } from "react-redux";
import EditAndDeleteButtons from "./EditAndDeleteButtons";
import { tableRoot, tableContainer, tableRow, box } from "./style";
import Filterbar from "./Filterbar/Filterbar";
import { useNavigate } from "react-router";
import { GetUnverifiedFacilities } from "../../store/actions";
import CircularIndeterminate from "./Loader";


const columns = [
  { id: "name", label: "Company Name", minWidth: 170 },
  { id: "phone_number", label: "Phone Num.", minWidth: 170 },
  {
    id: "created_at",
    label: "created_at",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "facility_type",
    label: "Facility Sport",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "button",
    label: "",
    minWidth: 70,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const Companies = ({
  realCompanies,
  MainSports,
  GetUnverifiedFacilities,
  Errors,
}) => {
  // <Filteration>
  const Sports = [...MainSports, { name: "All", id: 0 }];
  const FilteredSports = Sports.filter((sport) => sport.name !== "Running");
  const Time = [{ name: "Newest / Oldest", id: 0 }];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterValue, setFilterValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const router = useNavigate();

  useEffect(() => {
    return GetUnverifiedFacilities(setLoading);
  }, []);

  const handleFilter = (event) => {
    if (filterValue === 0) {
      realCompanies.reverse();
    }
    setFilterValue(event.target.value);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (loading) {
    return <CircularIndeterminate />;
  } else {
    return (
      <React.Fragment>
          <Filterbar
            filterValue={filterValue}
            handleFilter={handleFilter}
            Sports={FilteredSports}
            Time={Time}
          />
          {Errors && (
            <Alert severity="error" sx={{ fontSize: 16, mt: 4 }}>
              {Errors.message}
            </Alert>
          )}
          {realCompanies.length > 0 ? (
            <Paper sx={tableRoot} elevation={0}>
              <TableContainer sx={tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column, index) => (
                        <TableCell
                          key={index}
                          align={column.align}
                          sx={{
                            minWidth: column.minWidth,
                            fontSize: "1.4rem",
                            color: "text.tableHead",
                            fontWeight: 700,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {realCompanies
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .filter((res) =>
                        filterValue === 0
                          ? res
                          : res.facility_type === filterValue
                      )
                      .map((company, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            {columns.map((column, index) => {
                              const value = company[column.id];
                              if (column.id === "button") {
                                return (
                                  <TableCell key={index} align={column.align}>
                                    <EditAndDeleteButtons
                                      company={company}
                                      loader={setLoading}
                                      isApproved={company.is_approved}
                                    />
                                  </TableCell>
                                );
                              } else if (column.id === "created_at") {
                                return (
                                  <TableCell
                                    key={index}
                                    align={column.align}
                                    sx={tableRow}
                                    onClick={() => {
                                      router(`${company.id}`);
                                    }}
                                  >
                                    {column.format && typeof value === "number"
                                      ? new Date(value).toLocaleString()
                                      : value}
                                  </TableCell>
                                );
                              } else if (column.id === "facility_type") {
                                let currentSport = MainSports?.filter(
                                  (sport) => sport.id === value
                                );
                                return (
                                  <TableCell
                                    key={index}
                                    align={column.align}
                                    onClick={() => {
                                      router(`${company.id}`);
                                    }}
                                    sx={tableRow}
                                  >
                                    <img
                                      src={currentSport[0].icon_url}
                                      height="24px"
                                      alt="icons"
                                    />
                                  </TableCell>
                                );
                              }
                              return (
                                <TableCell
                                  key={index}
                                  align={column.align}
                                  sx={tableRow}
                                  onClick={() => {
                                    router(`${company.id}`);
                                  }}
                                >
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={realCompanies?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ bottom: 0, position: "absolute", right: 0 }}
              />
            </Paper>
          ) : (
            <Box
              sx={{
                flexGrow: 1,
                height: "75vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1>Unfortunately there are no facilities!</h1>
            </Box>
          )}
      </React.Fragment>
    );
  }
};
const mapStateToProps = (state) => {
  return {
    realCompanies: state.admin.UnverifiedFacilities,
    MainSports: state.admin.MainSports,
    Errors: state.admin.GeneralErrors,
  };
};
export default connect(mapStateToProps, { GetUnverifiedFacilities })(Companies);
