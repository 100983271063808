const initState = {
  // isLogin: false,
  reservations: [],
  users: [],
  alreadyFetchedUsers: [],
  LoginErrors: {},
  GeneralErrors: null,
  LoginResponse: {},
  UnverifiedFacilities: [],
  FacilityFields: [],
  reFetchReservation: false,
  MainSports: [
    {
      id: "EmjrJ2qsgWMJmUqba3ls",
      icon_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/default-sport-icons%2Fpaintball-icon.png?alt=media&token=0196f2c1-fc14-4914-8820-b714557b0494",
      name: "Paintball",
    },
    {
      id: "kqbyshOuGRzxkpErOkXu",
      icon_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/default-sport-icons%2Fkarting-icon.png?alt=media&token=1b087d7b-e9b4-4921-bea2-21c48f126401",
      name: "Karting",
    },
    {
      id: "pKWOqxUfarL7qAHpq8mZ",
      icon_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/default-sport-icons%2Frunning-icon.png?alt=media&token=bccf52c4-0948-4141-b543-aefb01defc4a",
      name: "Running",
    },
    {
      id: "un8ibSPNn1UXubMDqADI",
      icon_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/default-sport-icons%2Ffootball-icon.png?alt=media&token=aa21ba43-2fa7-44c1-b78f-dbaf37e815c0",
      name: "Football",
    },
    {
      id: "vwHQjEDVtDcd0dC65k5g",
      icon_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/default-sport-icons%2Flasertag-icon.png?alt=media&token=cf0a4c98-19a3-40b6-a36c-d401581ef7df",
      name: "Lasertag",
    },
    {
      id: "0fLvwJ4Z8UXtxCfGApNV",
      icon_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/default-sport-icons%2Fbasketball_icon.png?alt=media&token=a14cf66a-0cbe-4707-aec5-a2703f12e43e",
      name: "Basketball",
    },
  ],
  Features: [
    {
      id: "1Vy8ca8FLZvpExRS8iYA",
      image_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/deafult-feature-icons%2Frent-shoes.png?alt=media&token=9485201e-e058-4794-b094-aba11c41d871",
      type: "Football",
      sport_id: "un8ibSPNn1UXubMDqADI",
      name: "Rent Shoes",
    },
    {
      id: "20OER2oRxImTBSv2OHVU",
      type: "Karting",
      name: "9 Horsepower",
      sport_id: "kqbyshOuGRzxkpErOkXu",
      image_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/deafult-feature-icons%2F6hp.png?alt=media&token=ab6949d4-6c26-4320-9a95-d34854a9c806",
    },
    {
      id: "4bJb6es20uNY7Z8WCQUJ",
      sport_id: "un8ibSPNn1UXubMDqADI",
      type: "Football",
      name: "Rent Goalkeeper",
      image_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/deafult-feature-icons%2Frent-goalkeeper.png?alt=media&token=2fa9eda1-05aa-470f-97d9-9946d31cbbf4",
    },
    {
      id: "51CWju5f2r0yxrEXLuWO",
      sport_id: "un8ibSPNn1UXubMDqADI",
      name: "Praying Area",
      type: "Football",
      image_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/deafult-feature-icons%2Fflag-grabing.png?alt=media&token=53d3fe6d-f6f8-4447-9dc8-2a18d866f8f5",
    },
    {
      id: "6gcRQO31njWzZZukKrE7",
      type: "Football",
      sport_id: "un8ibSPNn1UXubMDqADI",
      image_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/deafult-feature-icons%2Frent-referee.png?alt=media&token=587762bb-b7ea-4e45-9c38-018e4aa2f22f",
      name: "Rent Referee",
    },
    {
      id: "Ba31N0VnmxCFFC0cnzqz",
      image_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/deafult-feature-icons%2Fparking.png?alt=media&token=8887bccf-007a-4407-9416-84528ef84c57",
      name: "Car Park",
      type: "General",
    },
    {
      id: "BozNFMzBJaUPVFF88eiS",
      name: "Lighting",
      image_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/deafult-feature-icons%2Flighting.png?alt=media&token=f4b39ce7-fd0f-412a-ad97-8f196ff7a926",
      type: "Karting",
      sport_id: "kqbyshOuGRzxkpErOkXu",
    },
    {
      id: "Hf541rXRr8kzMmrFcwVG",
      name: "Cafe",
      type: "General",
      image_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/deafult-feature-icons%2Fcafe.png?alt=media&token=3f300509-076b-4d9a-956d-e30e1abc45a4",
    },
    {
      id: "PK7pjsYO1Fm85lNavmNW",
      type: "Karting",
      image_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/deafult-feature-icons%2Fprotective.png?alt=media&token=45315016-4a0a-4571-b4dc-56590240678a",
      sport_id: "kqbyshOuGRzxkpErOkXu",
      name: "Protective Equipment",
    },
    {
      id: "RTUTSNTp6hSoQzwDCmSC",
      type: "General",
      image_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/deafult-feature-icons%2Fflag-grabing.png?alt=media&token=53d3fe6d-f6f8-4447-9dc8-2a18d866f8f5",
      name: "Indoor",
    },
    {
      id: "SyfupVGk0VC1R6CTbvMg",
      type: "General",
      image_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/deafult-feature-icons%2Fcamera.png?alt=media&token=c6d5e5aa-36cd-4e5d-9210-54f10fe94463",
      name: "Camera",
    },
    {
      id: "VJRpICs2tVo3mYp7Esbr",
      type: "Football",
      sport_id: "un8ibSPNn1UXubMDqADI",
      image_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/deafult-feature-icons%2Fflag-grabing.png?alt=media&token=53d3fe6d-f6f8-4447-9dc8-2a18d866f8f5",
      name: "Kids Play Area",
    },
    {
      id: "mECyhfJenDlwOkMmeiuJ",
      name: "Dressing Room",
      type: "General",
      image_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/deafult-feature-icons%2Fdressing-room.png?alt=media&token=63c671c2-cffc-4b90-94f8-468a500eea11",
    },
    {
      id: "n1CdVMkt1jmfLlKBgdaI",
      name: "Lap Time Recording",
      sport_id: "kqbyshOuGRzxkpErOkXu",
      type: "Karting",
      image_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/deafult-feature-icons%2Flap-time-recording.png?alt=media&token=a04447ac-c72b-4152-97e0-c4ea5bf5a77a",
    },
    {
      id: "pD40vFH3r9SxziMzSNYz",
      image_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/deafult-feature-icons%2Fflag-grabing.png?alt=media&token=53d3fe6d-f6f8-4447-9dc8-2a18d866f8f5",
      name: "WiFi",
      type: "General",
    },
    {
      id: "sK6QqrFSNpxKPk3xvhOq",
      image_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/deafult-feature-icons%2Fflag-grabing.png?alt=media&token=53d3fe6d-f6f8-4447-9dc8-2a18d866f8f5",
      type: "General",
      name: "Outdoor",
    },
    {
      id: "vBqaXEo4n9tLHhZYkdUH",
      sport_id: "kqbyshOuGRzxkpErOkXu",
      name: "6 Horsepower",
      type: "Karting",
      image_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/deafult-feature-icons%2F6hp.png?alt=media&token=ab6949d4-6c26-4320-9a95-d34854a9c806",
    },
    {
      id: "y01E75DU7cGOYMeyLL9Y",
      type: "Paintball",
      image_url:
        "https://firebasestorage.googleapis.com/v0/b/sports-app-8ce7c.appspot.com/o/deafult-feature-icons%2Fflag-grabing.png?alt=media&token=53d3fe6d-f6f8-4447-9dc8-2a18d866f8f5",
      name: "Flag Grabbing",
      sport_id: "EmjrJ2qsgWMJmUqba3ls",
    },
  ],
};


export const clone = (data) => {
  if ([Array, Object].includes(data?.constructor)) {
    data = Object.entries(data).reduce(
      (cloned, [key, value]) =>
        cloned.constructor === Array
          ? [...cloned, clone(value)]
          : {
            ...cloned,
            [key]: clone(value),
          },
      data.constructor === Array ? [] : {}
    );
  }

  return data;
};


export const objectUpdateNestedKey = (data, strKey, value) => {
  const keys = strKey.split(".");
  const currentKey = keys[0];
  const nextKey = keys[1];

  let clonedData = data;
  if (clonedData && [Array, Object].includes(clonedData.constructor)) {
    clonedData = clone(data);
    if (!nextKey) {
      clonedData[currentKey] = value;
    } else if (currentKey) {
      let currentDataValue = clonedData[currentKey];
      if ([undefined, null].includes(clonedData[currentKey])) {
        currentDataValue = !isNaN(nextKey) ? [] : {};
      }

      clonedData[currentKey] = objectUpdateNestedKey(
        currentDataValue,
        keys.slice(1).join("."),
        value
      );
    }
  }

  return clonedData;
};

const adminReducer = (state = initState, action) => {
  return objectUpdateNestedKey(state, action.type, action.payload);
};

export default adminReducer;
