import { Grid, Typography } from "@mui/material";
import React from "react";

import Select from "./Selector/Select";

const Filterbar = () => {
  const Sports = ["All","Football", "Karting","Paintball","Laser Tag"];
  const Time = ["Newest", "Old"];

  return (
    <Grid container spacing={4} alignItems="center">
      <Grid item>
        <Select label="Show" data={Time} />
      </Grid>
      <Grid item>
        <Select label="Sport" data={Sports} />
      </Grid>
    </Grid>
  );
};

export default Filterbar;
