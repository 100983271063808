import React from "react";
import Avatar from "@mui/material/Avatar";
import { AvatarStyle } from "./style";
import { connect } from "react-redux";

const Profile = ({ profilePic }) => {
  return <Avatar alt="Remy Sharp" sx={AvatarStyle} src={profilePic} />;
};
const mapStateToProps = (state) => ({
  profilePic: state.admin.LoginResponse?.data?.my_info?.profile_pic,
});

export default connect(mapStateToProps, {})(Profile);
