import React from "react";
import { ModalStyle, ModalTopBar, ModalTitle, ApplyButton } from "./style";
import { Box, InputAdornment, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from "formik";
import InputHandler from "../inputHandler";
import { FieldIcon } from "../../../Companies/SingleCompany/GeneralInfo/style";
import passwordIcon from "../../../../assets/icons/password-icon.png";
const ChangePassword = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle}>
        <Box sx={ModalTopBar}>
          <ModalTitle variant="h1" component="div">
            Change Password
          </ModalTitle>
          <CloseIcon
            sx={{ fontSize: "32px" }}
            onClick={() => {
              handleClose();
            }}
          />
        </Box>
        <Box sx={{ flexGrow: 1, mt: 2 }}>
          <Formik initialValues={{ currentPass: "", newPass: "",confirmNewPass:""}}>
            {(formikProps) => (
              <Form>
                <InputHandler
                  name="currentPass"
                  placeholder="Current Password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FieldIcon src={passwordIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <InputHandler
                  name="newPass"
                  placeholder="Current Password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FieldIcon src={passwordIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <InputHandler
                  name="confirmNewPass"
                  placeholder="Confirm New Password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FieldIcon src={passwordIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <ApplyButton>Apply</ApplyButton>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
};
export default ChangePassword;
