import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

export const navButton = {
  color: "default.main",
  ml: "2rem",
  mb: 2,
  background: "default.main",
};

export const logOutButton = {
  marginBottom: "2rem",
  color: "#fff",
  padding: "1.2rem 1.6rem",
  textTransform: "capitalize",
  width: "50%",
  display: "flex",
  justifyContent: "flex-start",
  borderRadius: "16px",
  "&:hover": {
    color: "#643fdb",
    backgroundColor: "white",
  },
};

export const NavButton = styled(Button)({
  color: "#fff",
  marginBottom: "1.2rem",
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  borderRadius: "1.6rem",
  padding: "1.2rem 1.6rem",
  fontFamily: "NetflixMedium",
  textTransform: "capitalize",
  fontSize: "1.8rem",
  "&:hover": {
    backgroundColor: "#fff",
    color: "#643fdb",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#fff",
    color: "#643fdb",
    borderColor: "#005cbf",
  },
  "&:focus": {
    backgroundColor: "#fff",
    color: "#643fdb",
  },
});
