import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { InputSelect, StyleSelect, LabelStyle } from "./style";
import MuiSelect from "@mui/material/Select";
const Select = ({ label, defaultValue, data }) => {
  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <Box sx={{ minWidth: 120 }} fullWidth>
      <FormControl
        fullWidth
        sx={{
          borderRadius: "2rem",
        }}
      >
        <InputLabel
          id="demo-simple-select-label"
          sx={{
            color: "#A29EB6",
            fontFamily: "NetflixBold",fontSize:"1.4rem"
          }}
        >
          {label}
        </InputLabel>
        <MuiSelect
          value={value}
          onChange={handleChange}
          input={<InputSelect />}
          sx={{
            disaply: "flex",
            alignItems: "center",fontSize:"1.4rem"
          }}
        >
          {data.map((res,index) => (
            <MenuItem key={index} value={res} sx={{fontSize:"1.4rem"}}>{res}</MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    </Box>
  );
};

export default Select;
