import React from "react";
import { Container, Time, Title } from "./style.js";
import { Box, Grid, Typography } from "@mui/material";
import ArrowUp from "../../../assets/icons/arrowup-icon.png";
import Users from "../../../assets/icons/user-icon.png";
import Boxd from "../../../assets/icons/3dbox-icon.png";
import Dollar from "../../../assets/icons/dollar-icon.png";
import Card from "./Card/Card.js";

const Analytics = [
  { name: "Reservations", value: "230k", icon: ArrowUp, color: "#643FDB1F" },
  { name: "Users", value: "230k", icon: Users, color: "#EF5DA81F" },
  { name: "Facilities", value: "230k", icon: Boxd, color: "#F9B2331F" },
  { name: "Revenue", value: "230k", icon: Dollar, color: "#51BF5C1F" },
];

const StatisticsBar = () => {
  return (
    <Box sx={Container}>
      <Grid container spacing={4} direction="column">
        <Grid container item justifyContent={"space-between"}>
          <Typography variant="h1" component="div" sx={Title}>
            Statistics
          </Typography>
          <Typography variant="h1" component="div" sx={Time}>
            Updated 1min ago
          </Typography>
        </Grid>
        <Grid container item rowGap={4}>
          {Analytics.map((card, index) => (
            <Card key={index} data={card} />
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default StatisticsBar;
