import EuropeWest from "../../API's/EuropeWest";
import { LOGGED_IN, TOKEN } from "../reducers/types";

/**
 * Login - This Login() is a redux action which handles the Login event for speicific user
 * @type {Function}
 * @param {Object}
 * @returns {Boolean}  - Returns Token,Email,Username.... to the redux state called "Login"
 */
export const login = (values) => {
  return async (dispatch) => {
    dispatch({
      type: "LoginErrors",
      payload: null,
    });
    try {
      const response = await EuropeWest.post("/admin/login", {
        ...values,
      });
      console.log("response", response);
      let data = response.data;
      if (data.success) {
        dispatch({
          type: LOGGED_IN,
          payload: true,
        });
        // getting data
        dispatch({
          type: "LoginResponse",
          payload: data,
        });
        dispatch({
          type: TOKEN,
          payload: data.token,
        });
      }
      return data;
    } catch (error) {
      let data = error.response.data;
      // getting errors
      dispatch({
        type: "LoginErrors",
        payload: data,
      });
      return data;
    }
  };
};

/**
 * GetUnverifiedFacilities - This GetUnverifiedFacilities() is a redux action which get all of the facilities that didn't verified yet..
 * @type {Function}
 * @param {Object}
 * @returns {Boolean}  - Returns Arrau of unverified facilities to the redux state called "UnverifiedFacilities"
 */
export const GetUnverifiedFacilities = (setLoading, values) => {
  return async (dispatch, getState) => {
    console.log("first");
    try {
      setLoading(true);
      const response = await EuropeWest.get("/admin/getUnverifiedFacilities", {
        headers: {
          authorization: `Bearer ${getState().token}`,
          "Content-type": "application/json",
        },
      });
      const data = response.data;
      console.log(data, "facs");
      if (data.success) {
        setLoading(false);

        dispatch({
          type: "UnverifiedFacilities",
          payload: data?.data,
        });
      }
    } catch (error) {
      setLoading(true);
      const status = error?.response.status;
      if (status === 403 || status === 404) {
        dispatch({
          type: LOGGED_IN,
          payload: false,
        });
      }
      console.log(error.response);
    }
  };
};
export const signOut = () => {
  return {
    type: LOGGED_IN,
    payload: false,
  };
};
/**
 * fetchReservations - This fetchReservations() is a redux action which get all of the Reservations ( AUTOMATIC & MANUAL )
 * @type {Function}
 * @param {Object}
 * @returns {Boolean}  - Returns Arrau of Reservations to the redux state called "reservations"
 */
export const fetchReservations = (lastRecordTimestamp = "") => {
  return (dispatch, getState) => {
    EuropeWest.post(
      "/admin/getReservations",
      {
        last_query_result: lastRecordTimestamp,
      },
      {
        headers: {
          authorization: `Bearer ${getState().token}`,
          "Content-type": "application/json",
        },
      }
    )
      .then(({ data }) => {
        console.log(data.data);
        dispatch({
          type: "reservations",
          payload: [...getState()?.admin?.reservations, ...data.data],
        });
        // console.log("response", data);
      })
      .catch((e) => {
        if (e?.response?.status === 403) {
          dispatch({
            type: LOGGED_IN,
            payload: false,
          });
        }
        console.log(e.response);
      });
  };
};

/**
 * fetchUsers - This fetchUsers() is a redux action which get all of the users in the database
 * @type {Function}
 * @param {Object}
 * @returns {Boolean}  - Returns Arrau of Users to the redux state called "users"
 */
export const fetchUsers = (lastUserTimeStamp) => {
  return (dispatch, getState) => {
    EuropeWest.post(
      "/admin/users/get",
      {
        last_query_result: lastUserTimeStamp, // [['1,'2]]
      },
      {
        headers: {
          authorization: `Bearer ${getState().token}`,
          "Content-type": "application/json",
        },
      }
    )
      .then(({ data }) => {
        console.log(data.data);
        dispatch({
          type: "users",
          payload: data.data,
        });
        // console.log("response", data);
      })
      .catch((e) => {
        if (e.response.status === 403) {
          dispatch({
            type: LOGGED_IN,
            payload: false,
          });
        }
        console.log(e.response);
      });
  };
};

/**
 * cancelReservation - This cancelReservation() is a redux action which cancel a specific reservation
 * @type {Function}
 * @param {Object}
 * @returns {Boolean}
 */
export const cancelReservation = (reservation, setSpinner) => {
  return (dispatch, getState) => {
    console.log(reservation.reservation_id);
    EuropeWest.delete(`/admin/delete/${reservation.reservation_id}`, {
      headers: {
        authorization: `Bearer ${getState().token}`,
        "Content-type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        setSpinner(false);
        dispatch({
          type: "reFetchReservation",
          payload: true,
        });
      })
      .catch((e) => {
        console.log(e.response);
        setSpinner(false);
      });
  };
};

/**
 * deleteCompany - This deleteCompany() is a redux action which delete a specific company
 * @type {Function}
 * @param {Object}
 * @returns {Boolean}
 */
export const deleteCompany = (company, setSpinner, handleClose) => {
  return (dispatch, getState) => {
    console.log(company.id);
    EuropeWest.post(
      `/admin/facility/delete`,
      {
        facility_id: company.id,
      },
      {
        headers: {
          authorization: `Bearer ${getState().token}`,
          "Content-type": "application/json",
        },
      }
    )
      .then((res) => {
        console.log(res);

        const filterdCompanies = getState().admin.UnverifiedFacilities.filter(
          (el) => el.id !== company.id
        );

        dispatch({
          type: "UnverifiedFacilities",
          payload: filterdCompanies,
        });
        setSpinner(false);

        handleClose();
      })
      .catch((e) => {
        console.log(e.response);
        setSpinner(false);
      });
  };
};

/**
 * approveCompany - This approveCompany() is a redux action which approve specific company so we can see it in the mobile app
 * @type {Function}
 * @param {Object}
 * @returns {Boolean}
 */
export const approveCompany = (company, setSpinner, handleClose) => {
  return (dispatch, getState) => {
    console.log(company.id);
    EuropeWest.post(
      `/admin/facility/approve`,
      {
        facility_id: company.id,
      },
      {
        headers: {
          authorization: `Bearer ${getState().token}`,
          "Content-type": "application/json",
        },
      }
    )
      .then(async (res) => {
        const modifiedFacilities =
          getState().admin.UnverifiedFacilities?.filter(
            (el) => el.id !== company.id
          );
        dispatch({
          type: "UnverifiedFacilities",
          payload: modifiedFacilities,
        });
        setSpinner(false);
        handleClose();
      })
      .catch((e) => {
        console.log(e.response);
        setSpinner(false);
      });
  };
};


/**
 * GetFacilityFields - This GetFacilityFields() is a redux action which GET reserved fields of a specific facility
 * @type {Function}
 * @param {Object}
 * @returns {Boolean}
 */
export const GetFacilityFields = (id) => {
  return async (dispatch, getState) => {
    try {
      const response = await EuropeWest.post(
        "/field/getFacilityFields",
        { facility_id: id },
        {
          headers: {
            authorization: `Bearer ${getState().token}`,
            "Content-type": "application/json",
          },
        }
      );
      const data = response.data;
      if (data.success) {
        dispatch({
          type: "FacilityFields",
          payload: data?.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const companySearch = (searchTerm = "") => {
  return async (dispatch, getState) => {
    try {
      const { data } = await EuropeWest.get(
        `/admin/company/look/${searchTerm}`,
        {
          headers: {
            authorization: `Bearer ${getState().token}`,
            "Content-type": "application/json",
          },
        }
      );

      const { company } = data.data;
      const modifiedFacilities = data.data.facilities.map((facility, index) => {
        return { ...facility, user_info: company[0] };
      });

      dispatch({
        type: "UnverifiedFacilities",
        payload: modifiedFacilities,
      });
      console.log(data.data);
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: "GeneralErrors",
        payload: error.response.data,
      });
      setTimeout(() => {
        dispatch({
          type: "GeneralErrors",
          payload: null,
        });
      }, 1500);
    }
  };
};

/**
 * fetchAllSportTypes - This fetchAllSportTypes() is a redux action which get all of the sports in the database
 * @type {Function}
 * @param {Object}
 * @returns {Boolean}  - Returns Array of sports to the redux state called "sports"
 */
export const getAllSportTypes = () => {
  return (dispatch, getState) => {

    EuropeWest.get(
      "/constants/getsports",
      {
        headers: {
          authorization: `Bearer ${getState().token}`,
          "Content-type": "application/json",
        },
      }
    )
      .then(({ data }) => {
        console.log(data);
        dispatch({
          type: "MainSports",
          payload: data.sportsList,
        });
        // console.log("response", data);
      })
      .catch((e) => {
        if (e.response.status === 403) {
          dispatch({
            type: LOGGED_IN,
            payload: false,
          });
        }
        console.log(e.response);
      });
  };
};