import axios from "axios";

export default axios.create({
  baseURL:
    "https://europe-west1-sports-app-8ce7c.cloudfunctions.net/app/api/v1",
  method: "POST",
  mode: "no-cors",
  headers: {
    "content-type": "application/json",
  },
});
