import React, { useState } from "react";
import {
  IconButton,
  Grid,
  Typography,
  Button,
  Paper,
  Divider,
  Modal,
  Backdrop,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import CloseIcon from "@mui/icons-material/Close";
import * as classes from "./style";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
const AlertModal = ({ open, handleClose, action, element,type,loader }) => {
 const dispatcher = useDispatch()
 const router = useNavigate()
  const [spinner, setSpinner] = useState(false);
  const handleYesClick = () => {
    setSpinner(true);
    dispatcher(action(element, setSpinner,handleClose,router));
  };
 
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      sx={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Paper sx={classes.modalPaper} elevation={0}>
        <Grid container item justifyContent="space-between" xs={12}>
          <Grid item xs={11}>
            <Typography sx={classes.title} variant="h6" color="secondary">
              <b>Alert</b>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              sx={classes.closeButton}
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon color="disabled" />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <Grid style={{ marginTop: "40px" }} container item>
          <Grid container justifyContent="center" item xs={12} spacing={3}>
            <Typography sx={classes.modalBody} variant="body" color="secondary">
              Are You Sure You Want To {type}
              <strong>" {element.reserver_name?element.reserver_name:element.name} "</strong> {element.reserver_name?'Reservation':'Company'} In Your
              Data ?
            </Typography>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={6}>
              {spinner ? (
                <Button
                  sx={classes.buttonYes}
                  type="submit"
                  variant="outlined"
                  fullWidth
                >
                  <CircularProgress size={30} />
                </Button>
              ) : (
                <Button
                  sx={classes.buttonYes}
                  type="submit"
                  variant="outlined"
                  fullWidth
                  onClick={handleYesClick}
                >
                  Yes
                </Button>
              )}
            </Grid>

            <Grid item xs={6}>
              <Button
                sx={classes.buttonNo}
                type="submit"
                variant="outlined"
                fullWidth
                onClick={handleClose}
              >
                No
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default AlertModal;
