export const tableRoot = {
  width: "100%",
  position: "relative",
  bottom: "6rem",
  borderRadius: "1.5rem",
  boxShadow: "0 .3rem 1rem 0 #e2e5ec ",
  marginTop: "100px",
};
export const tableRow = {
  fontSize: "1.4rem",
  color: "text.primary",
  fontWeight: 500,
};
export const tableContainer = {
  maxHeight: 590,
  borderRadius: "1.5rem",
  fontSize: "1.6rem",
};

export const container = {
  maxHeight: 440,
};
export const buttonIconEdit = {
  color: "default.main",
  borderRadius: "2rem",
  //margin: "0 10px",
  transition: "all .5s ease-in-out",
  fontSize: "1rem",
  minWidth: "7.6rem",
  padding: ".5rem 0 .2rem 0",
  "&:hover": {
    backgroundColor: "default.main",
    color: "error.main",
  },
};
export const buttonIconDelete = {
  backgroundColor: "#F5365C",
  borderRadius: "10px",
  margin: "0 10px",
  transition: "all .5s ease-in-out",
  "&:hover": {
    backgroundColor: "#FFF",
    color: "#F5365C",
  },
};
export const buttonIconMore = {
  backgroundColor: "#ffffff",
  color: "#696974",
  borderRadius: "10px",
  margin: "0 0 0 10px",
  transition: "all .5s ease-in-out",
  zIndex: 0,
  "&:hover": {
    backgroundColor: "#FFF",
    color: "primary.main",
  },
};
export const header = {
  marginLeft: "48px",
  zIndex: "1 !important",
  color: "#ffffff",
  height: "100px",
};
export const button = {
  marginTop: "0",
  height: "50px",
  width: "200px",
  marginRight: "110px",
  padding: "22px",
  backgroundColor: "white",
  textTransform: "capitalize",
  // color: "#4a3b85",
  fontWeight: "bolder",
  borderRadius: "15px",
  fontSize: "15px",
  transition: "all .5s ease-in-out",
  "&:hover": {
    backgroundColor: "white",
    color: "#4a3b85",
  },
};
export const title = {
  marginBottom: "20px",
};

export const modal = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
export const paper = {
  backgroundColor: "red",
  padding: "20px 60px",
  borderRadius: "30px",
  // boxShadow: theme.shadows[5],
  maxWidth: "900px",
};
export const input = {
  backgroundColor: "#ffffff",
  ["& fieldset"]: {
    borderRadius: 10,
    boxShadow: "0 3px 10px 0 #e2e5ec ",
    Opacity: ".1",
    border: "none",
  },
};
export const buttonUpload = {
  width: "220px",
  height: "10px",
  padding: "22px",
  backgroundColor: "#4a3b85",
  textTransform: "capitalize",
  color: "white",
  borderRadius: "15px",
  fontSize: "14px",
  transition: "all .5s ease-in-out",
  "&:hover": {
    backgroundColor: "#8868f2",
    color: "white",
  },
};
export const buttonAdd = {
  marginTop: "40px",
  marginBottom: "60px",
  height: "50px",
  textTransform: "capitalize",
  fontWeight: "bolder",
  borderRadius: "10px",
  fontSize: "14px",
  backgroundColor: "#4a3b85",
  color: "white",
  transition: "all .5s ease-in-out",
  "&:hover": {
    backgroundColor: "#8868f2",
    color: "white",
  },
};
export const modalPaper = {
  position: "relative",
  backgroundColor: "red",
  padding: "20px 60px",
  borderRadius: "30px",
  boxShadow: "red",
  maxWidth: "900px",
};
export const closeButton = {
  position: "asolute",
  left: "60px",
  bottom: "10px",
};
export const inputFile = {
  display: "none",
};

export const moreIcon = {
  fontSize: "medium",
  color: "red",
};
export const buttonDelete = {
  marginTop: "40px",
  marginBottom: "60px",
  height: "50px",
  textTransform: "capitalize",
  fontWeight: "bolder",
  borderRadius: "10px",
  fontSize: "14px",
  backgroundColor: "#4a3b85",
  color: "#ffffff",
  transition: "all .5s ease-in-out",
  "&:hover": {
    backgroundColor: "red",
    color: "#ffffff",
  },
};
export const span = {
  color: "#ffffff",
  fontSize: "1.6rem",
};
export const rootMoreIcon = {
  //  display: "flex",
};

export const moreDropDown = {
  marginRight: "7rem",
  padding: "8px",
  bgcolor: "#F3F6FF",
  color: "#A29EB6",
  borderRadius: 2,
  boxShadow: "0px 3px 12px rgba(0,0,0,.12)",
  zIndex: 999,
};
export const menuItem = {
  fontSize: "1.4rem",
  padding: "0 .5rem",
  color: "text.tableHead",
  fontWeight: 400,
};
export const box = {
  width: "1.8rem",
  height: "1.8rem",
  borderRadius: "50%",
  borderRadius: "50%",
  ml: "1rem",
  mr: "2rem",
  color: "GrayText.tableHead",
};
