import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  Badge,
  Box,
  Typography,
} from "@mui/material";

import { connect } from "react-redux";
import EditAndDeleteButtons from "./EditAndDeleteButtons";
import Loader from "./Loader";
import { tableRoot, tableContainer, tableRow, box } from "./style";
import { fetchUsers } from "../../store/actions";
import Filterbar from "./Filterbar/Filterbar";

const columns = [
  { id: "username", label: "Username", minWidth: 170 },
  { id: "phone_number", label: "Phone Num.", minWidth: 170 },
  {
    id: "games",
    label: "Games",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "sports",
    label: "Sports",
    minWidth: 100,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "button",
    label: "",
    minWidth: 70,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const Users = ({ users, fetchUsers }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleChangePage = (event, newPage) => {
    const isLastRecord = users.length < 20 ? true : false;
    if (isLastRecord) return;
    const lastRecordIndex = users.length - 1;
    const lastRecordTimestamp = users[lastRecordIndex].created_at;
    fetchUsers(lastRecordTimestamp);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <React.Fragment>
      <Filterbar />
      {users ? (
        <Paper sx={tableRoot} elevation={0}>
          <TableContainer sx={tableContainer}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      sx={{
                        minWidth: column.minWidth,
                        fontSize: "1.4rem",
                        color: "text.tableHead",
                        fontWeight: 700,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((reservation, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column, index) => {
                          if (column.id === "button") {
                            return (
                              <TableCell key={index} align={column.align}>
                                <EditAndDeleteButtons
                                  reservation={reservation}
                                />
                              </TableCell>
                            );
                          }
                          const value = reservation[column.id];

                          return (
                            <TableCell
                              key={index}
                              align={column.align}
                              sx={tableRow}
                            >
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={users?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    users: state.admin.users,
  };
};
export default connect(mapStateToProps, { fetchUsers })(Users);
