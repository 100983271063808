import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import InputHandler from "../GeneralInfo/inputHandler";
import InputAdornment from "@mui/material/InputAdornment";
import { FieldIcon, SubmitBtn } from "../GeneralInfo/style";
import UserIcon from "../../../../assets/icons/user-icon-violet.png";
import AddressIcon from "../../../../assets/icons/address-icon.png";
import PhoneIcon from "../../../../assets/icons/phone-icon.png";
import UsersIcon from "../../../../assets/images/Dual User.png";
import Pitch from "./Pitch/Pitch";
import Feature from "./Features/Features";
import PitchImage from "./PitchImage/PitchImage";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { GetFacilityFields } from "../../../../store/actions";
const SportFacility = ({
  UnverifiedFacilities,
  GetFacilityFields,
  FacilityFields,
}) => {
  const { id } = useParams();
  const CurrentFacility = UnverifiedFacilities?.filter(
    (facility) => facility.id === id
  );
  //   const initialValues = {
  //     id: "",
  //     facility_type: "",
  //     extra_features: [],
  //     name: "",
  //     features: [],
  //     phone_number: "",
  //     about: "",
  //    address:"",
  //     user_id: "",
  //        images_url:[],
  //   };
  const initialValues = {
    ...CurrentFacility[0],
    ...CurrentFacility[0].user_info,
  };
  console.log(initialValues, "from facility");

  useEffect(() => {
    GetFacilityFields(id);
  }, []);

  return (
    <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
      <Container maxWidth={"md"} sx={{ mt: 4 }}>
        <Formik initialValues={initialValues} enableReinitialize={true}>
          {(formikProps) => (
            <Form>
              {/* ======== Facility Name */}
              <Box>
                <InputHandler
                  placeholder={"Full Name"}
                  name={"name"}
                  type={"text"}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <FieldIcon src={UserIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                {/* ======== Address */}
                <InputHandler
                  placeholder={"Address"}
                  name={"address"}
                  type={"text"}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <FieldIcon src={AddressIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                {/* ======== PhoneNumber */}
                <InputHandler
                  placeholder={"Phone Number"}
                  name={"phone_number"}
                  type={"text"}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <FieldIcon src={PhoneIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              {/* ======== Pitches ===================== */}
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant={"h1"}
                  component={"div"}
                  sx={{ fontSize: "2rem", fontFamily: "NetflixMedium" }}
                >
                  Pitches
                </Typography>
                <div
                  style={{
                    display: "flex",
                    gap: "2.4rem",
                    marginTop: "2.4rem",
                    overflowX: "scroll",
                    flexGrow: 1,
                    paddingBottom: "1.2rem",
                  }}
                >
                  {FacilityFields?.map((pitch) => (
                    <Pitch data={pitch} />
                  ))}
                </div>
              </Box>
              {/* ======== Features ===================== */}

              <Box sx={{ flexGrow: 1, mt: 4 }}>
                <Typography
                  variant={"h1"}
                  component={"div"}
                  sx={{ fontSize: "2rem", fontFamily: "NetflixMedium" }}
                >
                  Features
                </Typography>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  {formikProps.values.features.map((feature) => (
                    <Grid item xs={4} key={feature}>
                      <Feature id={feature} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              {/* ======== About ===================== */}

              <Box sx={{ flexGrow: 1, mt: 4 }}>
                <Typography
                  variant={"h1"}
                  component={"div"}
                  sx={{ fontSize: "2rem", fontFamily: "NetflixMedium" }}
                >
                  About
                </Typography>
                <Typography
                  variant={"h1"}
                  component={"div"}
                  sx={{
                    fontSize: "1.6rem",
                    fontFamily: "NetflixRegular",
                    color: "#696974",
                    mt: 2,
                  }}
                >
                  {formikProps.values.about}
                </Typography>
              </Box>
              {/* ======== Image of Pitches ===================== */}
              <Box sx={{ flexGrow: 1, mt: 4 }}>
                <Typography
                  variant={"h1"}
                  component={"div"}
                  sx={{ fontSize: "2rem", fontFamily: "NetflixMedium" }}
                >
                  Image of Pitch
                </Typography>
                <div
                  style={{
                    display: "flex",
                    gap: "2.4rem",
                    marginTop: "2.4rem",
                    overflowX: "scroll",
                    flexGrow: 1,
                    paddingBottom: "1.2rem",
                  }}
                >
                  {formikProps.values.images_url.map((pitch, index) => (
                    <PitchImage img={pitch} key={index} />
                  ))}
                </div>
              </Box>
              <SubmitBtn type={"submit"}>Delete Company</SubmitBtn>
            </Form>
          )}
        </Formik>
      </Container>
    </Box>
  );
};

const mapStateTopProps = (state) => {
  return {
    UnverifiedFacilities: state.admin.UnverifiedFacilities,
    FacilityFields: state.admin.FacilityFields,
  };
};
export default connect(mapStateTopProps, { GetFacilityFields })(SportFacility);
