import React from 'react'
import Box from "@mui/material/Box";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {BellStyle, shapeStyles, StyledBadge} from './style'

const Notifications = () => {
    const badgeContent = <Box component="span" sx={shapeStyles}>
        <NotificationsNoneIcon style={BellStyle} className={"bell"}/>
    </Box>;
    return <StyledBadge color="secondary" variant={"dot"} overlap="circular">
        {badgeContent}
    </StyledBadge>
}

export default Notifications
