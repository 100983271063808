import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { PitchName, Pitch as PitchStyled } from "./style";
import UsersIcon from "../../../../../assets/images/Dual User.png";
import ClockIcon from "../../../../../assets/images/Circle Clock.png";
import Activity from '../../../../../assets/images/Activity.png'
function Pitch({ data }) {
  const {
    name,
    id,
    duration,
    max_capacity: max,
    min_capacity: min,
    size,
    price,
  } = data;
  return (
    <PitchStyled>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PitchName variant={"h1"} component={"div"}>
            {name}
          </PitchName>
        </Grid>
        <Grid item xs={6}>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <img src={UsersIcon} width={"24px"} />
            <Typography
              variant={"p"}
              component={"div"}
              sx={{ fontSize: "1.2rem", color: "#fff" }}
            >
              Players {min} to {max}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <img src={ClockIcon} width={"24px"} />
            <Typography
              variant={"p"}
              component={"div"}
              sx={{ fontSize: "1.2rem", color: "#fff" }}
            >
              Time {duration} min
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <img src={Activity} width={"24px"} />
            <Typography
              variant={"p"}
              component={"div"}
              sx={{ fontSize: "1.2rem", color: "#fff" }}
            >
              Price {price} TL
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <img src={Activity} width={"24px"} />
            <Typography
              variant={"p"}
              component={"div"}
              sx={{ fontSize: "1.2rem", color: "#fff" }}
            >
              Length {size}M
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </PitchStyled>
  );
}

export default Pitch;
