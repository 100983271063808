import { TOKEN } from "./types";
import { LOGGED_IN } from "./types";

const AuthReducer = (state = false, action) => {
  switch (action.type) {
    case LOGGED_IN:
      return action.payload;
    default:
      return state;
  }
};
export const TokenReducer = (state = "", action) => {
  switch (action.type) {
    case TOKEN:
      return action.payload;
    default:
      return state;
  }
};
export default AuthReducer;
