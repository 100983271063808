import React from "react";
import IMG from "../../../../../assets/images/pitchImage.png";
import { Box } from "@mui/material";

function PitchImage(props) {
  return (
    <Box sx={ContainerStyle}>
      <img src={props.img} alt="" style={ImageStyle} />
    </Box>
  );
}

export default PitchImage;

const ContainerStyle = {
  minWidth: "25rem",
  height: "17rem",
  borderRadius: "1rem",
};
const ImageStyle = {
  objectFit: "cover",
  width: "100%",
  height: "100%",
  borderRadius: "1rem",
};
