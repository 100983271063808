import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  Badge,
  Box,
  Typography,
} from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import EditAndDeleteButtons from "./EditAndDeleteButtons";
import Loader from "./Loader";
import { tableRoot, tableContainer, tableRow, box } from "./style";
import Filterbar from "../Global/Filterbar/Filterbar";
import { fetchReservations } from "../../store/actions";
import moment from "moment";
import ReservationModal from "./ReservationModal/Modal";

const columns = [
  { id: "reserver_name", label: "Reservation Details", minWidth: 170 },
  { id: "name", label: "Facility Name", minWidth: 170 },
  {
    id: "price",
    label: "Price",
    minWidth: 170,
    align: "left",
  },
  {
    id: "time",
    label: "Date",
    minWidth: 170,
    align: "left",
    formatDate: (value) => {
      return moment.unix(value).format("MMMM DD, YYYY ");
    },
  },
  {
    id: "button",
    label: "Status",
    minWidth: 170,
    align: "left",
  },
];

const Reservation = ({ reservations = [] }) => {
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [price, setPrice] = useState([0, 0]);
  const dispatcher = useDispatch();
  const reFetchReservation = useSelector(
    (state) => state?.admin?.reFetchReservation
  );
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
  };

  const handlePriceChange = (e) => {
    console.log(price);
    console.log(reservations);
  };

  useEffect(() => {
    dispatcher(fetchReservations());
  }, [reFetchReservation]);

  const handleChangePage = (event, newPage) => {
    const isLastPage = reservations?.length < 20 ? true : false;
    if (isLastPage) return;
    const lastRecordIndex = reservations?.length - 1;
    const lastRecordTimestamp = reservations[lastRecordIndex]?.created_at;
    dispatcher(fetchReservations(lastRecordTimestamp));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <React.Fragment>
      <Filterbar
        price={price}
        handlePriceChange={handlePriceChange}
        setPrice={setPrice}
      />
      {reservations ? (
        <Paper sx={tableRoot} elevation={0}>
          <TableContainer sx={tableContainer}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns?.map((column, index) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      sx={{
                        minWidth: column.minWidth,
                        fontSize: "1.4rem",
                        color: "text.tableHead",
                        fontWeight: 700,
                      }}
                    >
                      {column?.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {reservations
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((reservation, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                      >
                        {columns?.map((column, index) => {
                          if (column?.id === "button") {
                            return (
                              <TableCell key={index} align={column.align}>
                                <EditAndDeleteButtons
                                  reservation={reservation}
                                />
                              </TableCell>
                            );
                          }
                          else if (
                            column?.id === "name" ||  column.id === "price"
                          ) {
                            const value = reservation?.field_info  && reservation?.field_info[column.id];
                            return (
                              <TableCell
                                key={index}
                                align={column.align}
                                sx={tableRow}
                              >
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          }
                          const value = reservation[column.id];
                          return (
                            <TableCell
                              key={index}
                              align={column.align}
                              sx={tableRow}
                            >
                              {column.formatDate
                                ? column.formatDate(value)
                                : value}
                            </TableCell>
                          );
                        })}
                        <ReservationModal
                          open={open}
                          handleClose={handleClose}
                          data={reservation}
                        />
                      </TableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TableRow></TableRow>
              </TableFooter>
            </Table>

            <Grid container>
              <Grid
                item
                xs={2}
                justifyContent="flex-start"
                alignItems="center"
                container
              >
                <Badge>
                  <Box component="span" sx={box} bgcolor="primary.main" />
                </Badge>
                <Typography color="text.tableHead" fontSize="1.4rem">
                  Upcoming ( Normal Game )
                </Typography>
              </Grid>
              <Grid
                item
                xs={2.5}
                justifyContent="flex-start"
                alignItems="center"
                container
              >
                <Badge>
                  <Box component="span" sx={box} bgcolor="secondary.main" />
                </Badge>
                <Typography color="text.tableHead" fontSize="1.4rem">
                  Pending ( Problem Need To Solve )
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                justifyContent="flex-start"
                alignItems="center"
                container
              >
                <Badge>
                  <Box component="span" sx={box} bgcolor="error.main" />
                </Badge>
                <Typography color="text.tableHead" fontSize="1.4rem">
                  Cancel ( Canceled Reservation )
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TablePagination
                  rowsPerPageOptions={[20,25]}
                  component="div"
                  count={reservations?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </TableContainer>
        </Paper>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    reservations: state.admin.reservations,
  };
};
export default connect(mapStateToProps)(Reservation);
