import React from "react";
import {useNavigate, Routes} from "react-router-dom";
import {Tab, TabsList, TabPanel} from "./style";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import GeneralInfo from "./GeneralInfo/GeneralInfo";
import SportFacility from "./SportFacility/SportFacility";

const CompanyPage = () => {
    const router = useNavigate();

    return (
        <TabsUnstyled defaultValue={0} sx={{width: "80%"}}>
            <TabsList>
                <Tab>General Info</Tab>
                <Tab>Sport Facility</Tab>
            </TabsList>
            <TabPanel value={0}>
                <GeneralInfo/>
            </TabPanel>
            <TabPanel value={1}>
                <SportFacility/>
            </TabPanel>
        </TabsUnstyled>
    );
};

export default CompanyPage;
