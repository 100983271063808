import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

export const SearchBar = styled(TextField)({
  "& label": {
    display: "none",
  },
  "& .MuiOutlinedInput-root": {
    fontSize: "1.6rem",
    fontFamily: "NetflixMedium !important",
    background: "#F3F6FF",
    borderRadius: "2rem",
    color: "#A29EB6",
    "& fieldset": {
      border: "none",
      fontFamily: "NetflixMedium !important",
      color: "#A29EB6",
      width: "inherit",
      transition: "all ease-in-out .4 !important",
    },
    "&.Mui-focused fieldset": {
      border: "2px solid",
      borderColor: "#643fdb",
    },
    "&.Mui-focused.MuiOutlinedInput-root": {
      width: "300px",
    },
  },
});
