import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";

export const SideImage = styled("img")(({ theme }) => ({
  height: "100vh",
  objectFit: "contain",
  borderRadius: "32px 0 0 32px",
  position: "absolute",
  top: "0",
  right: "0",
  zIndex: 1,
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));
export const formContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
export const nImageContainer = {
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-end",
};
export const hidden = {
  display: "none",
};
export const form = {
  width: "70%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  padding: "1.2rem",
  gap: "3.2rem",
};

export const InputField = styled(TextField)({
  "& label": {
    display: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    height: "7rem",
    fontSize: "1.6rem",
    fontFamily: "NetflixMedium !important",
    "& fieldset": {
      border: "none",
      boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.14)",
      borderRadius: "1rem",
      padding: "0 3.2rem",
    },
    "&.Mui-focused fieldset": {
      border: "2px solid",
      borderColor: "#643fdb",
    },
  },
});

export const SubmitBtn = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#643fdb",
  height: "7rem",
  fontSize: "1.6rem",
  fontFamily: "NetflixBold",
  borderRadius: "1rem",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.14)",
  marginTop: "3.2rem",
  "&:hover": {
    backgroundColor: "#643fdb",
    color: "#fff",
  },
}));

export const FieldIcon = styled("img")(({ theme }) => ({
  height: "2.7rem",
  width: "2.7rem",
  cursor: "pointer",
}));

export const TitleStyle = {
  maxWidth: "18rem",
  fontSize: "4.8rem",
  fontFamily: "NetflixMedium",
  borderBottom: "3px solid #643fdb",
  paddingBottom: "1.2rem",
  marginBottom: "4.8rem",
};
