import * as React from 'react';
import {CircularProgress,Box} from "@mui/material";

const CircularIndeterminate = () => {
    return (
        <Box sx={{ display: 'flex',justifyContent:'center',alignItems:'center',marginTop:'200px'}} >
          <CircularProgress color="secondary" />
        </Box>
      )
}

export default CircularIndeterminate