import React from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import SportsIcon from "@mui/icons-material/Sports";
import { connect } from "react-redux";

function Feature({ id, features }) {
  const CurrentFeature = features.filter((feature) => feature.id === id);
  const { name, image_url: img } = CurrentFeature[0];
  return (
    <Paper elevation={0} sx={PaperStyle} key={id}>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <img src={img} style={{ width: "24px", height: "24px" }} />
        <Typography sx={FeatureTitle} variant={"h1"} component={"div"}>
          {name}
        </Typography>
      </Stack>
    </Paper>
  );
}
const mapStateToProps = (state) => {
  return {
    features: state.admin.Features,
  };
};
export default connect(mapStateToProps, {})(Feature);

const PaperStyle = {
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  //   py: 2,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
  height: "60px",
  px:2,
  width: "100%",
};

const FeatureTitle = {
  fontSize: "14px",
  fontFamily: "NetflixRegular",
};
